import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, Typography } from '@mui/material';
import pallete from 'TGComponents/theme/pallete';
import ClickAwayListener from '@mui/material/ClickAwayListener';

const TGDropdownV2 = ({
  children,
  options,
  customIcon,
  square,
  disabled = false,
  dropdownCustom = false,
  bgColorIconButton,
  heightIconButton,
  paddingIconButton,
  borderRadiusIconButton,
  borderIconButton,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      {children ? (
        <Box onClick={handleClick}>{children}</Box>
      ) : (
        <IconButton
          aria-label='more'
          aria-controls={open ? 'tg-dropdown-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup='true'
          onClick={handleClick}
          sx={{
            height: heightIconButton,
            bgcolor: bgColorIconButton || '#fafafa',
            padding: paddingIconButton,
            borderRadius: borderRadiusIconButton || '8px',
            border: borderIconButton || '1px solid #EEEEEE',
          }}
          disabled={disabled}
        >
          {customIcon ? customIcon : <MoreVertIcon />}
        </IconButton>
      )}

      <Menu
        id='tg-dropdown-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          '& .MuiPaper-root': {
            'borderRadius': '8px',
            'minWidth': 180,
            'boxShadow':
              'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
            '& .MuiMenu-list': {
              padding: '0',
              fontSize: '2px',
            },
            '& .MuiMenuItem-gutters': {
              fontSize: '14px',
              fontWeight: '400',
            },

            '& .MuiMenuItem-root': {
              '& .MuiSvgIcon-root': {
                fontSize: 18,
              },
            },
          },
        }}
        slotsPaperProps={{
          style: {
            maxHeight: options.length * 48,
            width: '20ch',
          },
        }}
      >
        {dropdownCustom ? (
          <>
            {options.map((option) => (
              <Box>
                {option?.optionDefault && (
                  <Box
                    sx={{
                      padding: '15px',
                      borderBottom: option?.dropdownTitleBorder,
                    }}
                  >
                    <Typography
                      color={pallete.base['blackAlpha']}
                      fontWeight={400}
                    >
                      {option?.optionDefault}
                    </Typography>
                  </Box>
                )}
                {option?.label && (
                  <MenuItem
                    key={option.label}
                    disabled={option.disabled}
                    onClick={() => {
                      if (option.onClick) {
                        option.onClick();
                      }
                      handleClose();
                    }}
                    style={{
                      background:
                        option?.selectedOption === option?.label
                          ? '#F2F9FC'
                          : '#FFF',
                      color:
                        option?.selectedOption === option?.label
                          ? pallete.base['black']
                          : pallete.base['blackAlpha'],
                      display: 'flex',
                      gap: '45px',
                      minHeight: '21px',
                      padding: '12px 16px',
                      fontWeight: 400,
                    }}
                  >
                    <Typography> {option.label}</Typography>

                    <Typography
                      color={pallete.base['blackAlpha']}
                      mr='10px'
                      fontSize='0.75rem'
                      fontWeight={400}
                    >
                      {option?.description}
                    </Typography>
                  </MenuItem>
                )}
              </Box>
            ))}
          </>
        ) : (
          <>
            {options.map((option) => (
              <MenuItem
                key={option.label}
                disabled={option.disabled}
                onClick={() => {
                  if (option.onClick) {
                    option.onClick();
                  }
                  handleClose();
                }}
                style={{ minHeight: '21px', padding: '12px 16px' }}
              >
                {option.label}
              </MenuItem>
            ))}
          </>
        )}
      </Menu>
    </Box>
  );
};

export default TGDropdownV2;
